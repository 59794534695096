<template>
    <div class="footerphone">
        <div class="topnav">
            <div class="left_img">
                <img src="@/assets/footer1.png" alt="">
                <img src="@/assets/footer2.png" alt="">
            </div>
            <div class="right_text">
                <p>{{props.en?'Copyright © UCAS':'@中国科学院大学经济与管理学院版权所有'}}</p>
                <p v-if="!props.en">地址：北京市海淀区中关村南一条3号   &nbsp;&nbsp;&nbsp;&nbsp;  邮编：100190 </p>
                <p v-else>Address: No.19A Yuquan Road, Beijing 100049, China</p>
            </div>
        </div>
        <div class="bottomnav">
            <div class="left">
                <div class="ewm1 ewm">
                    <img src="@/assets/erweima2.png" alt="">
                    <p>{{props.en?'WeChat':'微信公众号'}}</p>
                </div>
                <div class="ewm2 ewm">
                    <img src="@/assets/erweima1.png" alt="">
                    <p>{{props.en?'Weibo':'新浪微博'}}</p>
                </div>
            </div>
            <div class="right">
                <p>{{props.en?'Link':'友情链接'}}</p>
                <span @click="push_xb('https://www.las.ac.cn')">{{props.en?'National Science Library':'中国科学院文献情报中心(国家图书馆)'}}</span>
                <span @click="push_xb('https://www.ucas.ac.cn')">{{props.en?'UCAS':'中国科学院大学'}}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref,reactive,defineProps} from 'vue'
const props = defineProps({
    en:Boolean
})
let push_xb = (ee) => { window.open(ee) }
</script>
<style lang="less" scoped>

@maxw: 1020/100vw;
@maxw_: 750/100vw;
.footerphone{
    width: 100%;
    height: 520/@maxw;
    background-image: url("../assets/footerBack.png");
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 100/@maxw 0 0 50/@maxw;
    .topnav{
        display: flex;
        align-items: center;
        height: 81/@maxw;
       .left_img{
        display: flex;
        width: 385/@maxw;
        height: 100%;
         img{
            width: 191/@maxw;
            height: 100%;
            vertical-align: top;
        }
        >img:nth-child(2){margin-left: 25/@maxw;}
       }
        .right_text{
            margin-left: 48/@maxw;
            p{
                font-size: 18/@maxw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 300;
                color: #fff;
                margin: 0;
            }
        }
    }
    .bottomnav{
        display: flex;
        align-items: center;
        margin-top: 115/@maxw;
        .left{
            display: flex;
            img{width: 146/@maxw;height: 146/@maxw;vertical-align: top;}
            p{
            font-size: 18/@maxw;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            color: #D1BAA6;
            }
            .ewm{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .ewm2{margin-left: 32/@maxw;}
        }
        .right{
            display: flex;
            flex-direction: column;
            margin-left: 35/@maxw;
            p{
                margin: 0 0 10/@maxw 0;
                font-size: 26/@maxw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 300;
                color: #fff;
            }
            span{
                font-size: 18/@maxw;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 300;
                color: #fff;
                margin-top: 5/@maxw;
            }
        }
    }
}
@media screen and (max-width:750px) {
    .footerphone{
        height: 625/@maxw_ !important;
        background-image: url("../assets/iphone_back.png");
    }
    .topnav{
        flex-direction: column !important;
        justify-content: left !important;
        align-items: baseline !important;
        .left_img{
            img{
                width: 260/@maxw_ !important;
                height: 110/@maxw_ !important;
            }
        }
        .right_text{
            margin: 90/@maxw_ 0 0 0 !important;
            p{
                font-size: 22/@maxw_ !important;
            }
        }
    }
    .bottomnav{
        margin-top: 270/@maxw_ !important;
        img{
            width: 142/@maxw_ !important;
            height: 142/@maxw_ !important;
        }
        .left{
            font-size: 18/@maxw_ !important;
        }
        .right{
            p{
                font-size: 22/@maxw_ !important;
            }
            span{
                font-size: 18/@maxw_ !important;
            }
        }
    }
}
</style>