<template>
  <header class="headers" ref="headers">
    <!-- 黑色蒙版,和搜索页面 -->
    <div class="mengban" ref="mengban" @click.stop="offMenban">
      <div class="serach">
        <input type="text" placeholder="请输入关键字" id="bs" v-model="keywords">
        <img id="bs" src="@/assets/phoneSearch.png" @click="search_ok">
      </div>
    </div>
    <div class="left_logo" @click="router.push('/')"><img src="@/assets/logo3.png" alt=""></div>
    <div class="right_search">
      <span @click="navSearch_"><img src="@/assets/sousuo.png" alt=""></span>
      <span @click="navMenr_"><img src="@/assets/menu.png" alt="" ref="navMenr_img"></span>
    </div>
    <div class="navMenu" ref="navMenu">
      <div class="navlist" ref="navlist">
        <ul>
          <li @click="pushOpen('https://www.ucas.ac.cn')">国科大首页</li>
          <li>|</li>
          <li @click="pushOpen('https://sep.ucas.ac.cn/')">业务平台</li>
          <li>|</li>
          <li>学院内网</li>
          <li>|</li>
          <li @click="pushOpen('https://sep.ucas.ac.cn/')">用户登录</li>
          <li>|</li>
          <li @click="pushen">EN</li>
<!--          <li @click="pushOpen('https://mscas-en.ucas.ac.cn/')">EN</li>-->
        </ul>
      </div>
      <div class="navSubmenu" ref="navSubmenu">
        <ol ref="ol">
          <li v-for="(item,i) in navList" :key="i" @click.stop="onClick_one(item,i)">
            <span>{{item.title}}</span>
            <em v-if="item.children"><img src="https://s1.ax1x.com/2022/06/22/jp8EY4.png" alt=""></em>
            <!-- 判断有无二级渲染二级菜单 -->
            <div class="submenu_two" ref="submenu2" v-show="item.children">
              <nav v-for="(item2,i2) in item.children" :key="i2" @click.stop="onClick_two(item,i,item2,i2)">
                <span>{{item2.title}}</span>
                <em v-if="item2.children"><img src="https://s1.ax1x.com/2022/06/22/jp8EY4.png" alt=""></em>
                <!-- 判断有无三级并渲染三级菜单 -->
                <div class="submenu_three" v-if="item2.children">
                  <section v-for="(item3,i3) in item2.children" :key="i3" @click.stop="onClick_three(item,item2,item3,i2,i3,i)">{{item3.title}}</section>
                </div>
              </nav>
            </div>
          </li>
        </ol>
      </div>
    </div>
      
  </header>
</template>

<script setup>
import { useRouter,useRoute } from 'vue-router'
import {ref,reactive,onMounted,watch,computed, nextTick,defineProps,onUnmounted} from 'vue'
import * as http from '@/api/API.js'
import base64_ from '@/hooks/useBase64text.js'
import { getNavlist } from '@/api/API.js'
const router = useRouter()
const props = defineProps({
  navMenudata:Array
})
const headers = ref(null)
const navMenu = ref(null)
const navSubmenu = ref(null)
const ol = ref(null)
const submenu2 = ref(null)
const navMenr_img = ref(null)
const mengban = ref(null)
let flag = true

let navMenr_ = () => {
if(flag){
  // headers.value.style.background = '#0D2F62'
  navMenu.value.style.transform = 'scale(1)'
  navSubmenu.value.style.transform = 'scale(1)'
  navMenr_img.value.style.transform = 'rotate(90deg)'
  flag = false
}else{
  // headers.value.style.background = ''
  navMenu.value.style.transform = 'scale(0)'
  navSubmenu.value.style.transform = 'scale(0)'
  navMenr_img.value.style.transform = 'rotate(0)'
  flag = true
}
}
let navSearch_ = () => {
      mengban.value.style.display = 'block'
    setTimeout(() => {
    mengban.value.style.opacity = '1'
    },50)
}
// 点击蒙版关闭蒙版
let offMenban = (e) => {
  if(!e.target.id){
    mengban.value.style.opacity = '0'
    setTimeout(() => {
      mengban.value.style.display = 'none'
    },500)
    return
  }
}
let navList = ref([])
// 请求导航栏接口数据
let getnavData = async () => {
// 如果页面小于1020就不执行接口
  if(window.innerWidth >= 1020) return 
  const {data:res} = await getNavlist({is_banner_nav:1})
  if(res.code === 200){
    getNavlist({is_banner_nav:2}).then(res2 => {
      if(res2.data.code == 200){
        navList.value = [...res.data,...res2.data.data]
      }
    })
  }
} 
getnavData()
// 点击一级菜单事件
const onClick_one = (item, i) => {
   // 如果有外部链接，就打开
    if(item.external_url){
    window.open(item.external_url)
    return
  }
  if (item.children) {
    item.children.forEach((element, ii) => {
      if (element.children) {
        cliearLi_style(submenu2.value[i].children,80)
      }
    });
  }else{
     if(item.vue_url == '/'){
      router.push("/")
      }
      router.push({
        name:item.vue_url,
        params:{obj:base64_.encode(JSON.stringify({
        title1:item.title,
        index:-1,
        type_id:item.id,
        article_id:item.id==32?55:item.id,
    }))}
  })
    headers.value.style.background = ''
    navMenu.value.style.transform = 'scale(0)'
    navSubmenu.value.style.transform = 'scale(0)'
  }
  let olE = ol.value.children
  // 100代表每个li的高度
  cliearLi_style(olE,100)
  styleHeight(item,i,olE,100)
}
// 点击二级菜单事件
const onClick_two = (item,i,item2,i2) => {
    const element_three = submenu2.value[i].children
    // 80就是对应每个二级菜单的高度
    cliearLi_style(element_three,80)
    if(item2.children){
      let ol_li = ol.value.children[i]
      let ol_li_length = ol.value.children[i].children[2].children.length
      styleHeight(item2,i2,element_three,70,ol_li,ol_li_length)
    }else{
      styleHeight(item,i,ol.value.children,100)
    }
    // 如果有外部链接，就打开
    if(item2.external_url){
    window.open(item.external_url)
    return
    }
    // 如果template_type就默认跳转子菜单第一个
  if(item2.template_type == 1){
    router.push({
    name:item2.vue_url,
    params:{obj:base64_.encode(JSON.stringify(
      {
          title1:item2.title,
          title2:item2.children[0].title,
          id:item2.pid,
          index:i,
          flag:1,
          article_id:item2.id==34?55:item2.children[0].id,
          }
    ))}
    })
    return
  }
  // 如果都没有就跳转对应路由
  router.push({
    name:item2.vue_url,
    params:{obj:base64_.encode(JSON.stringify({
          title1:item.title,
          title2:item2.title,
          id:item.id,
          type_id:item2.id,
          index:i2,
          flag:1,
          article_id:item2.id==34?55:item2.id,
          isBanner_nav_id:item2.is_banner_nav,
          }))}
    })
  // 操作dom收起
  headers.value.style.background = ''
  navMenu.value.style.transform = 'scale(0)'
  navSubmenu.value.style.transform = 'scale(0)'
}
// 点击三级菜单事件
const onClick_three = (pritem,twoitem,item,ii,ind,i) => {
    router.push({
    name:item.vue_url,
    params:{obj:base64_.encode(JSON.stringify({  
          title1:pritem.title,
          title2:twoitem.title,
          title3:item.title,
          id:pritem.id,
          id4:item.children?item.children[0].id:'',
          i4:0,
          level:item.level,
          index:ii,
          active:ind,
          article_id:item.id
          }))}
    })
    headers.value.style.background = ''
  navMenu.value.style.transform = 'scale(0)'
  navSubmenu.value.style.transform = 'scale(0)'
}
// 清除之前样式
let cliearLi_style = (ele,height_) => {
  for(let i = 0; i < ele.length; i ++){
    ele[i].style.height = height_ / 7.5 + 'vw'
    if(ele[i].children[2]){
      ele[i].children[2].style.opacity = 0
      ele[i].children[2].style.visibility = 'hidden'
    }
  }
}
// 给li样式和高度
let styleHeight = (item,i,olE,height,ol_li,ol_li_length) => {
if(item.children){
    let olE_li = item.children.length
    // 动态给li设置高度
    olE[i].style.height = (olE_li * height) / 7.5 + (80/7.5) + 'vw'
    if(olE[i].children[2]){
      olE[i].children[2].style.opacity = 1
      olE[i].children[2].style.visibility = 'visible'
    }
    if(ol_li){
      ol_li.style.height = (ol_li_length * 100) / 7.5 + ((item.children.length * 70) /7.5) + (80/7.5) + 'vw'
    }
  }
}
import miiter from '@/utils/miitbus.js'
onMounted(_ => {
})
onUnmounted(()=>{
    miiter.off('mittFn_phone')
    miiter.off('endmittFn_phone')
})

import { ElNotification  } from 'element-plus'
let keywords = ref("")
const search_ok = () => {
  if(keywords.value.trim()){
    router.push({
    name:'Search',
    params:{obj:base64_.encode(JSON.stringify({
      title1:'搜索文章',
      id:32,
    }))}
  })
  window.sessionStorage.setItem("key",JSON.stringify(keywords.value))
  }else{
    ElNotification({
    title: '警告',
    message: '搜索内容不能为空！！！',
    type: 'warning',
    zIndex:9999999999
  })
  }
}

// 跳转新页面
let pushOpen = (str) => {
  window.open(str)
}

// 英文跳转
const  pushen = () => {
  document.documentElement.scrollTop = 0
  router.push("/en")
}

</script>

<style lang="less" scoped>

@maxw:1020/100vw;
@maxw_:750/100vw;
    .headers{
      width: 100%;
      position:fixed;
      top: 0;
      left: 50%;
      z-index: 999999999999 !important;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-between;
      transition: ease .5s;
      background-image: url('../assets/phoneHeaderback.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 70/@maxw_;
    .left_logo{
      margin-left: 68/@maxw;
      width: 600/@maxw;
      height: 70/@maxw;
      img{width: 100%;height: 100%;vertical-align: top;}
    }
    .right_search{
      display: flex;
      overflow: hidden;
      >span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96/@maxw;
        height: 96/@maxw;
        background: #1749944f;
        transition: ease .5s;
        img{
          width: 50%;
          height: 50%;
          vertical-align: top;
          transition: ease .5s;
        }
      }
      >span:nth-child(2){
        background: #0d2f62cb;
      }
    }
    .navMenu{
      transform: scale(0);
      position: absolute;
      top: 90/@maxw;
      z-index: -2 !important;
      width: 100%;
      background: linear-gradient(180deg, #0D2F62 0%, #174994 100%);
      transition: ease .3s;
      >.navlist{
        position: relative;
        z-index: 9999999 !important;
        overflow: auto;
        >ul{
          position: relative;
          z-index: 9999999 !important;
          transition: ease .3s;
          margin: 0;
          padding: 0;
          width: 100%;
          display: flex;
          margin-top: 30/@maxw_;
          padding-bottom: 30/@maxw_;
          align-items: center;
          justify-content: center;
          font-size: 21/@maxw_;
          color: #fff;
          border-bottom: 2px solid #ccc;
          >li:nth-child(2n){
            margin: 0 29/@maxw_;
          }
        }
      }
      .navSubmenu {
              transition: ease .3s;
              position: absolute;
              top: 82/@maxw_;
              // top: 200/@maxw;
              margin-top: 11/@maxw;
              width: 70%;
              padding-bottom: 160/@maxw_;
              background: #174994;
              transform: scale(0);
              z-index: 99999999999 !important;
          
              ol {
                background: #174994;
                margin: 50/@maxw_ 0 0 80/@maxw_;
                padding: 0;
                height: auto;
                list-style: none;
                height: 1200/@maxw_;
                overflow-y: scroll;
          
                li {
                  height: 100/@maxw_;
                  background-image: linear-gradient(to right, #9A9A9A 0%, #9A9A9A, transparent 80%);
                  background-size: 15/@maxw_ 1px;
                  background-repeat: repeat-x;
                  background-position: bottom;
                  font-size: 29/@maxw_;
                  font-family: Source Han Sans CN-Medium;
                  font-weight: 500;
                  color: #D1BAA6;
                  margin-right: 40/@maxw_;
                  line-height: 100/@maxw_;
                  transition: ease .5s;
          
                  >em {
                    float: right;
                    display: flex;
                    align-items: center;
                    width: 12/@maxw_;
                    margin: 40/@maxw_ 20/@maxw_ 0 0;
                    height: 20/@maxw_;
          
                    img {
                      width: 100%;
                      height: 100%;
                      vertical-align: top;
                    }
                  }
          
                  .submenu_two {
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    width: 100%;
                    height: 100%;
                    transition: ease .6s;
          
                    nav {
                      margin: 0 50/@maxw_ 0 0;
                      padding: 0;
                      font-size: 29/@maxw_;
                      height: 80/@maxw_;
                      position: relative;
                      transition: ease .5s;
                      text-align: end;
                      >em {
                        position: absolute;
                        top: -3/@maxw_;
                        right: -30/@maxw_;
                        // float: right;
                        display: flex;
                        align-items: center;
                        width: 12/@maxw_;
                        margin: 40/@maxw_ 0 0 20/@maxw_;
                        height: 20/@maxw_;
          
                        img {
                          width: 100%;
                          height: 100%;
                          vertical-align: top;
                        }
                      }
          
                      .submenu_three {
                        padding: 0;
                        margin-right: -50/@maxw_;
                        display: flex;
                        flex-direction: column;
                        // align-items: center;
                        align-items: flex-end;
                        transition: ease .6s;
                        opacity: 0;
                        visibility: hidden;
          
                        >section {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          margin: 0;
                          padding: 0;
                          height: 70/@maxw_;
                          overflow:hidden;
                          text-overflow:ellipsis;
                          display:-webkit-box;
                          -webkit-box-orient:vertical;
                          -webkit-line-clamp:1; 
                        }
                      }
                    }
                  }
                }
          
                >li:last-child {
                  background: 0;
                }
              }
        }
    }  
    }
.mengban{
  display: none;
}
@media screen and (max-width:750px) {
  .headers{
    .left_logo{
      width: 490/@maxw_ !important;
    }
    .right_search{
     >span{
      width: 96/@maxw_;
      height: 96/@maxw_;
     } 
    }
    .navMenu{
      top: 90/@maxw_;
    }
  }
  .navSubmenu{
    top: 86/@maxw_ !important;
    margin-top: 6/@maxw_ !important;
  }
  .mengban{
  opacity: 0;
  display: none;
  position: absolute;
  transition: ease .5s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000d3;
 .serach{
  position: absolute;
  top: 215/@maxw_;
  left: 50%;
  transform: translateX(-50%);
  height: 62/@maxw_;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999999999 !important;
   input{
    width: 442/@maxw_;
    height: 100%;
    border-radius: 50/@maxw_;
    z-index: 999;
    font-size: 30/@maxw_;
    padding-left: 30/@maxw_;
    border: none;
  }
  img{
    position: absolute;
    top: 13/@maxw_;
    right: 0/@maxw_;
    margin-right: 30/@maxw_;
    z-index: 9999 !important;
    width: 32/@maxw_;
    height: 32/@maxw_;
  }
 }
}
}
</style>