<template>
  <footer class="footer">
    <div class="footer_left">
      <div class="img_two" ref="img_two">
        <img src="../assets/footer1.png" alt="">
        <img src="../assets/footer2.png" alt="">
      </div>
      <div class="footer_text" ref="text_footer">
        <p>@中国科学院大学经济与管理学院版权所有</p>
        <p>地址:北京市海淀区中关村南一条3号 &nbsp &nbsp邮编:100190</p>
      </div>
      <div class="img_p_box" ref="img_p_box">
        <div class="erweima">
          <img src="../assets/erweima2.png" alt="">
          <p>微信公众号</p>
        </div>
        <div class="erweima">
          <img src="../assets/erweima1.png" alt="">
          <p>新浪微博</p>
        </div>
      </div>
      <div class="footer_a" ref="footer_a">
        <a>友情链接</a>
        <span v-for="(item,index) in data.link" @click="push_xb(item.jump_url)" :key="index">
          {{ item.title }}
        </span>
        <!--          <i @click="push_xb('https://www.ucas.ac.cn')">中国科学院大学</i>-->
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref, reactive, onMounted, } from 'vue'
import * as http from '@/api/API.js'

const data = reactive({
  link: []
})

let push_xb = (ee) => { window.open(ee) }
const img_two = ref(null)
const text_footer = ref(null)
const img_p_box = ref(null)
const footer_a = ref(null)
onMounted(_ => {
  // 判断如果底部区域进入可视区域就给动画
  const observer = new IntersectionObserver((entrys) => {
    for (const v of entrys) {
      if (v.isIntersecting) {
        Transition_to(img_two.value)
        Transition_to(text_footer.value)
        Transition_to(img_p_box.value)
        Transition_to(footer_a.value)
      } else {
        // observer.disconnect()
        Transition_from(img_two.value)
        Transition_from(text_footer.value)
        Transition_from(img_p_box.value)
        Transition_from(footer_a.value)
      }
    }
  }, { root: null, rootMargin: '0px', threshold: 0.5 });

  observer.observe(document.querySelector(".footer")); // 观察的目标元素footer
  const Transition_to = (element) => {
    element.style.opacity = 1
    element.style.transform = 'translateX(0px)'
  }
  const Transition_from = (element) => {
    if (!element) return
    if (element.className == 'img_two') {
      element.style.transform = 'translate(-60px,60px)'
    }
    else if (element.className == 'footer_text') {
      element.style.transform = 'translate(0,-60px)'
    }
    else if (element.className == 'img_p_box') {
      element.style.transform = 'translate(60px,60px)'
    }
    else if (element.className == 'footer_a') {
      element.style.transform = 'translateX(60px)'
    }
    element.style.opacity = 0
  }
  //
  getLink();
})


//友情链接
const getLink = () => {
  http.getHttp("/api/article?page=1&limit=10&type_id=37")
    .then(res => {
      data.link = res.data.data.data;
    })
}

</script>

<style lang="less" scoped>
@maxwidth: 128rem;

.footer {
  width: 1920/@maxwidth;
  height: 305/@maxwidth;
  background-image: url("../assets/dibubj.png");
  background-size: 100% 100%;
  font-family: Source Han Sans CN-Medium;

  .footer_left {
    display: flex;
    margin: 0 auto;
    width: 1588/@maxwidth;

    .img_two {
      margin: 7.1% 0 0 0 !important;
      padding: 0;
      display: flex;
      transition: ease .5s;

      >img {
        width: 230/@maxwidth;
        height: 97/@maxwidth;
      }
    }

    .footer_text {
      transition: ease .5s;
      // margin: 120/@maxwidth 0 0 45/@maxwidth;
      margin: 108/@maxwidth 0 0 45/@maxwidth;

      p {
        // font-size: 20/@maxwidth;
        width: 420/@maxwidth;
        // line-height: 1;
        color: #fff;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: 17/@maxwidth;
        // font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-family: Source Han Sans CN-Medium;
        font-weight: 300;
        color: #FFFFFF;
        margin-top: 40/@maxwidth;
      }

      >p:nth-child(2) {
        margin-top: 0;
      }
    }

    .img_p_box {
      transition: ease .5s;
      display: flex;
      margin: 0 0 0 50/@maxwidth;

      .erweima {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 100/@maxwidth 0 0 20/@maxwidth;

        >img {
          width: 110/@maxwidth;
          height: 110/@maxwidth;
        }

        >p {
          margin: 10/@maxwidth 0 0 0;
          font-size: 16/@maxwidth;
          color: #D1BAA6;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }

    .footer_a {
      cursor: pointer;
      transition: ease .5s;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      font-size: 12/@maxwidth;
      color: #fff;
      margin: 100/@maxwidth 0 0 33/@maxwidth;

      a {
        font-size: 18/@maxwidth;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      span {
        margin: 10/@maxwidth 0;
        width: 270/@maxwidth;
        font-size: 13/@maxwidth;
      }

      >span:hover {
        color: #D1BAA6;
      }

      i {
        font-style: unset;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      >i:hover {
        color: #D1BAA6;
      }
    }
  }

  .footer_left img:nth-child(2) {
    margin-left: 30/@maxwidth;
  }
}</style>
