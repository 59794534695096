<template>
  <div class="box_header" :id="props.type == 1 ? '' : 'box_header'" ref="box_header">
    <div class="header_bx">
      <!-- logo和右侧导航 -->
      <div class="logo_nav" ref="logo_nav">
        <div class="left_logo" ref="left_logo" @click="router.push('/')"><img src="@/assets/logo3.png"
            title="中国科学院大学 经济与管理学院"></div>
        <ul class="right_nav">
          <li @click="push_open('https://www.ucas.ac.cn')">国科大首页</li>
          <li>|</li>
          <li @click="push_open('https://sep.ucas.ac.cn/')">业务平台</li>
          <li>|</li>
          <li>学院内网</li>
          <li>|</li>
          <li @click="push_open('https://sep.ucas.ac.cn/')">用户登录</li>
          <li>|</li>
          <li>{{ dateTime }}</li>
          <li>|</li>
          <!--        <li @click="push_open('https://mscas-en.ucas.ac.cn/')">EN</li>-->
          <li @click="pushen">EN</li>
        </ul>
      </div>
    </div>
    <!-- 导航栏 -->
    <div class="navlist" :id="props.type == 1 ? '' : 'navlist_bgc'" ref="navlist">
      <div class="search_" @click="search_botton">
        <span>
          <img src="@/assets/sousuo.png" alt="" @click="search_ok">
          <!-- <button @click="search_ok">ok</button> -->
          <i><input v-model="keywords" @keydown="enter" placeholder="请输入关键字搜索" /></i>
        </span>
        <span>
          <img src="@/assets/menu.png" alt="">
          <div class="search_nav">
            <div class="top" @click="router.push('/')"><em class="span_">经管首页</em></div>
            <div class="nav">
              <div class="nav_list_box">
                <span class="spans" v-for="item in rightNavlist.slice(0, 11)" :key="item.id"
                  @click="rightClick(item)">{{ item.title }}</span>
              </div>
              <div class="nav_list_boxTwo">
                <span class="spans" v-for="item in rightNavlist.slice(12)" :key="item.id"
                  @click="rightClick(item)">{{ item.title }}</span>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="navlist_bx">
        <ol ref="ol">
          <!-- 遍历一级菜单 -->
          <li v-for="(item, i) in headerData.navList" :key="i" @mouseover="mouseover(i)" @mouseleave="mouseleave(i)"
            @click="pushArticel(item, i)">
            <span>{{ item.title }}</span>
            <div class="submenu" v-if="item.children">
              <!-- 遍历二级菜单 -->
              <div v-for="(item1, ii) in item.children" :key="ii" class="p_text"
                @click.stop="pushArticel2(item, item1, ii, i)" @mouseover="mouseover_(i, ii)" @mouseleave="mouseleave_(i, ii)">
                <span>{{ item1.title }}</span>
                <!-- 遍历三级菜单 -->
                <a v-if="item1.children"><img src="@/assets/icon2.png" alt=""></a>
                <div class="_smenu" v-if="item1.children">
                  <div v-for="(item2, iii) in item1.children" class="item3" :key="iii"
                    @click.stop="pushArticel3(item, item1, item2, ii, iii, i)">
                    <span>{{ item2.title }}</span>
                    <!-- 遍历四级菜单 -->
                    <a v-if="item2.children"><img src="@/assets/icon2.png" alt=""></a>
                    <div class="smenu_4" v-if="item2.children">
                      <p v-for="(item3, iiii) in item2.children" :key="iiii"
                        @click.stop="pushArticel4(item, item1, item2, item3, i, ii, iii, iiii)">{{ item3.title }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive, watch, onUnmounted } from 'vue'
import miiter from '@/utils/miitbus.js'
import { getNavlist, getRightnavlist } from '@/api/API.js'
import { useRouter, useRoute } from 'vue-router'
import { usegetTime } from '@/hooks/usegetTime.js'
import base64_ from '@/hooks/useBase64text.js'
const props = defineProps({
  type: String,
  atIndex: String
})
// 获取并且格式化时间
const dateTime = ref(usegetTime('YYYY年mm月dd日', new Date()))
const headerData = reactive({
  navList: []
})
let ol = ref(null)
let mouseover = (i,) => {
  let liCh = ol.value.children[i].children[1]
  if (liCh !== undefined) {
    liCh.className = 'submenu_mouseover'
    //如果鼠标移入最后一个导航栏，就往左显示
    if (i == ol.value.children.length - 1) {
      liCh.className = 'submenu_mouseover_end'
    }
  }
}
let mouseleave = (i) => {
  let liCh = ol.value.children[i].children[1]
  if (liCh !== undefined) {
    liCh.className = 'submenu_mouseleave'
  }
}
let mouseover_ = (i, ii) => {
  let sjMenu = ol.value.children[i].children[1].children[ii].children[2]
  if (sjMenu) {
    sjMenu.style.display = 'block'
    i == ol.value.children.length - 1 ? sjMenu.style.right = '100%' : ''
  }
}
let mouseleave_ = (i, ii) => {
  let sjMenu = ol.value.children[i].children[1].children[ii].children[2]
  if (sjMenu) {
    sjMenu.style.display = 'none'
  }
}
let router = useRouter()
let route = useRoute()
// 点击跳转
let push_open = (str) => {
  window.open(str)
}
// 点击一级导航栏
let pushArticel = (item, i) => {
  menu_gl(i)
  // 如果有外部链接就跳转
  if (item.external_url) {
    window.open(item.external_url)
    return
  }
  // 如果template_type就默认跳转子菜单第一个
  if (item.template_type == 1) {
    router.push({
      name: item.vue_url,
      params: {
        obj: base64_.encode(JSON.stringify(
          {
            title1: item.title,
            title2: item.children[0].title,
            id: item.id,
            index: 0,
            flag: 1,
            article_id: item.id == 34 ? 55 : item.children[0].id,
          }
        ))
      }
    })
    return
  }
  router.push({
    name: item.vue_url,
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: item.title,
        id: item.id,
        article_id: item.id == 32 ? 55 : item.id,
      }))
    }
  })
}
// 点击二级导航栏
let pushArticel2 = (pritem, item, ii, i) => {
  menu_gl(i)
  // 如果有外部链接就跳转
  if (item.external_url) {
    window.open(item.external_url)
    return
  }
  // 如果template_type就默认跳转子菜单第一个
  if (item.template_type == 1) {
    router.push({
      name: item.vue_url,
      params: {
        obj: base64_.encode(JSON.stringify(
          {
            // title1: item.title,
            title1: pritem.title,
            title2: item.children[0].title,
            id: pritem.id,
            index: ii,
            flag: 1,
            article_id: item.id == 34 ? 55 : item.children[0].id,
          }
        ))
      }
    })
    return
  }
  router.push({
    name: item.vue_url,
    params: {
      obj: base64_.encode(JSON.stringify(
        {
          title1: pritem.title,
          title2: item.title,
          id: pritem.id,
          index: ii,
          flag: 1,
          article_id: item.id == 34 ? 55 : item.id,
        }
      ))
    }
  })

}
// 点击三级导航栏
let pushArticel3 = (pritem, twoitem, item, ii, ind, i) => {
  menu_gl(i)
  // 如果有外部链接就跳转
  if (item.external_url) {
    window.open(item.external_url)
    return
  }
  router.push({
    name: item.vue_url,
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: pritem.title,
        title2: twoitem.title,
        title3: item.title,
        id: pritem.id,
        id4: item.children ? item.children[0].id : '',
        i4: 0,
        level: item.level,
        index: ii,
        active: ind,
        article_id: item.id
      }))
    }
  })
}
// 点击四级导航栏
let pushArticel4 = (pritem, twoitem, sitem, fitem, i1, i2, i3, i4) => {
  menu_gl(i4)
  // 如果有外部链接就跳转
  if (fitem.external_url) {
    window.open(fitem.external_url)
    return
  }
  router.push({
    name: fitem.vue_url,
    params: {
      obj: base64_.encode(JSON.stringify({
        title1: pritem.title,
        title2: twoitem.title,
        title3: sitem.title,
        title4: fitem.title,
        id: pritem.id,
        level: sitem.level,
        i4,
        id4: fitem.id,
        index: i2,
        active: i3,
        article_id: sitem.id
      }))
    }
  })
}
let menu_gl = (i) => {
  let oll = ol.value.children[i]
  for (let j = 0; j < ol.value.children.length; j++) {
    ol.value.children[j].style['border-bottom'] = '0'
    ol.value.children[j].children[0].style['font-weight'] = '350'
  }
  oll.style['border-bottom'] = '5px solid #fff'
  oll.children[0].style['font-weight'] = 'bold'
}
// 请求导航栏接口数据
let getnavData = async () => {
  // 如果页面小于1020就不执行接口
  if (window.innerWidth <= 1020) return
  const { data: res } = await getNavlist({ is_banner_nav: 1 })
  if (res.code === 200) {
    headerData.navList = res.data
    get_rightnav_list()
  }
}

const left_logo = ref(null)
const box_header = ref(null)
const navlist = ref(null)

onMounted(_ => {
  getnavData()
  if (props.atIndex) {
    ol.value.children[props.atIndex].style['border-bottom'] = '5/@maxw solid #fff'
  }
  //   // 滚动大于指定值时：
  miiter.on('mittFn', () => {
    // 判断页面元素是否为真或者是否渲染完毕，为真就给样式
    if (box_header.value) {
      props.type == 1 ? box_header.value.style.background = 'linear-gradient(180deg, #073579 0%, rgba(56, 125, 226, 0.22) 100%)' : ''
      props.type == 1 ? navlist.value.style.background = 'linear-gradient(180deg, #074096 0%, rgba(56, 125, 226, 0) 100%)' : ''

    }
  })
  //   // 滚动小于指定值时：
  miiter.on('endmittFn', () => {
    // 判断页面元素是否为真或者是否渲染完毕，为真就给样式
    if (box_header.value) {
      props.type == 1 ? box_header.value.style.background = 'none' : ''
      props.type == 1 ? navlist.value.style.background = 'none' : ''
    }
  })
})

onUnmounted(() => {
  miiter.off('mittFn')
  miiter.off('endmittFn')
})
// 搜索弹框
const drawer = ref(false)
const search_botton = () => {
  drawer.value = true
}
import { ElNotification } from 'element-plus'
let keywords = ref("")
const search_ok = () => {
  if (keywords.value.trim()) {
    if (route.name == 'Search') {
      window.sessionStorage.setItem("key", JSON.stringify(keywords.value))
      miiter.emit('seachKeyword', keywords.value)
      return
    }
    router.push({
      name: 'Search',
      params: {
        obj: base64_.encode(JSON.stringify({
          title1: '搜索文章',
          id: 32,
        }))
      }
    })
    window.sessionStorage.setItem("key", JSON.stringify(keywords.value))
    miiter.emit('seachKeyword', keywords.value)
  } else {
    ElNotification({
      title: '警告',
      message: '搜索内容不能为空！！！',
      type: 'warning',
      zIndex: 999999
    })
  }
}
let rightNavlist = ref([])
// 右侧导航数据
const get_rightnav_list = () => {
  getRightnavlist().then(res => {
    rightNavlist.value = res.data.data
  })

}

// 点击右侧导航
const rightClick = (item) => {
  //   // 如果有外部链接就跳转
  if (item.external_url) {
    window.open(item.external_url.slice(3))
    return
  }
  // 如果template_type为1，就跳转到第一条子栏目
  if (item.template_type == 1) {
    router.push({
      name: item.vue_url,
      params: {
        obj: base64_.encode(JSON.stringify(
          {
            title1: item.title,
            title2: item.children[0].title,
            type_id: item.children[0].id,
            id: item.children[0].pid,
            index: 0,
            article_id: item.children[0].id,
            isBanner_nav_id: item.is_banner_nav,
          }
        ))
      }
    })
    return
  }
  // 1不是轮播图底部入口
  if (item.is_banner_nav == 1) {
    router.push({
      name: item.vue_url,
      params: {
        obj: base64_.encode(JSON.stringify({
          title1: item.title,
          id: item.id,
          article_id: item.id == 32 ? 55 : item.id,
        }))
      }
    })
  }
  // 2是轮播图底部入口
  else if (item.is_banner_nav == 2) {
    router.push({
      name: item.vue_url,
      params: {
        obj: base64_.encode(JSON.stringify({
          title1: item.title,
          index: -1,
          article_id: item.id,
          id: item.id,
          isBanner_nav_id: item.id,
          type_id: item.id,
        }))
      }
    })
  }
  // 否则就是首页分类模块点击的
  else {
    const item_route = rightNavlist.value.find(item_ => item_.id == item.id)
    // 截取home首页分类的模块导航，方便跳转后对应article的背景图片
    let homeClass = rightNavlist.value.slice(-6)
    const routerIndex = homeClass.findIndex(item_ => item_.id == item.id)
    router.push({
      name: item_route.id == 26 ? "Xyxw" : item_route.vue_url,
      params: {
        obj: base64_.encode(JSON.stringify({
          type_id: item_route.id,
          title1: item_route.title,
          index: item.id == 38 ? 6 : routerIndex
        }))
      }
    })
  }

}
// 监听回车事件
const enter = (e) => {
  if (e.keyCode === 13) {
    search_ok()
  }
}

// 英文跳转
const pushen = () => {
  document.documentElement.scrollTop = 0
  router.push("/en")
}
</script>

<style lang="less" scoped>
@maxw: 128rem;

.box_header {
  box-sizing: border-box;
  position: fixed !important;
  top: 0;
  left: 50% !important;
  transform: translateX(-50%);
  transition: all .5s !important;
  width: 1920/@maxw;
  z-index: 99999 !important;
}

.header_bx {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 1595/@maxw;
  padding-top: 15/@maxw;
  transition: all .5s !important;
  padding-left: 3/@maxw;

  .logo_nav {
    width: 100%;
    height: 80/@maxw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: ease .3s;
    box-sizing: border-box;
    padding-left: 2/@maxw;

    .left_logo {
      transition: ease .3s;
      display: flex;
      align-items: center;
      width: 538/@maxw;
      justify-content: left;
      height: 60/@maxw;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        vertical-align: middle;
        margin-left: 0/@maxw;
      }
    }

    .right_nav {
      padding: 0;
      display: flex;
      align-items: center;
      margin-right: 3/@maxw;
      font-family: Source Han Sans CN-Medium;

      li {
        font-size: 17/@maxw;
        color: #fff !important;
        cursor: pointer;
      }

      >li:hover {
        font-weight: bold;
      }
    }

    .right_nav li:nth-child(2n) {
      margin: 0 20/@maxw;
    }

    .right_nav li:nth-child(1),
    .right_nav li:nth-child(9),
    .right_nav li:nth-child(11) {
      font-weight: bold !important;
    }
  }
}

.navlist {
  width: 100%;
  height: 60/@maxw;
  margin-top: 10/@maxw;

  .navlist_bx {
    box-sizing: border-box !important;
    padding-left: 6/@maxw;
    position: relative;
    width: 1595/@maxw;
    height: 100%;
    margin: 0 auto;
    font-size: 28/@maxw;
    font-family: Source Han Sans CN-Medium;
    color: #FFFFFF;

    >ol {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;

      >li {
        flex: 1;
        padding-right: 35/@maxw;
        height: 60/@maxw;
        cursor: pointer;
        line-height: 60/@maxw;
        transition: all .2s;
        text-align: right;

        .submenu {
          transform: scale(0);
          transition: all .1s;
        }

        .p_text {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0 0 35/@maxw;
          margin: 0;
          transition: all .5s;
          font-size: 26/@maxw;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 72/@maxw;

          >a {
            margin-right: 10/@maxw;
            width: 9/@maxw;
            height: 15/@maxw;

            img {
              width: 100%;
              height: 100%;
              vertical-align: top;
            }
          }

          ._smenu {
            position: absolute;
            top: 0;
            right: -100%;
            margin: 0;
            padding: 0;
            width: 100%;
            background-color: #0e3c7ce2;
            // transform:scale(0);
            display: none;
            transition: all .1s;
            z-index: 9999;
            text-align: center;

            .item3 {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: center;
              transition: all .2s;

              >span {
                justify-content: center;
                display: flex;
                flex: 1;
                height: 100%;
                // margin-left: 100/@maxw;
              }

              >a {
                margin-right: 10/@maxw;
                width: 9/@maxw;
                height: 15/@maxw;

                img {
                  width: 100%;
                  height: 100%;
                  vertical-align: top;
                }
              }

              >.smenu_4 {
                position: absolute;
                top: 0;
                width: 100%;
                right: -100%;
                background-color: #0e3c7ce2;
                display: none;

                >p {
                  padding: 0;
                  margin: 0;
                  color: #fff;
                  font-weight: 400 !important;
                }

                >p:hover {
                  color: #263C62 !important;
                  font-weight: bold !important;
                  background-image: url("../assets/xuanzhong2.png");
                  background-size: 100% 100%;
                  background-repeat: no-repeat;
                }
              }
            }

            >.item3:hover {
              background-image: url("../assets/xuanzhong2.png");
              background-size: 100% 100%;
              color: #263C62;
              font-weight: bold;

              .smenu_4 {
                display: block;
              }
            }
          }

          >span {
            transition: ease .2s;
          }
        }

        .p_text:hover {
          background-image: url("../assets/xuanzhong2.png");
          background-size: 100% 100%;

          >span {
            font-weight: bold;
            // font-size: 22/@maxw;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            color: #0D2F62;
            line-height: 72/@maxw;
          }
        }

        >span {
          font-weight: 500;
          transition: all .2s !important;
        }
      }

      >li:hover {
        border-bottom: 5/@maxw solid #fff;

        >span {
          font-weight: bold !important;
        }
      }

      >li:last-child {
        text-align: right;
        margin: 0;
        padding: 0;
      }

      >li:first-child {
        text-align: left;
        margin: 0;
        padding: 0;
      }
    }

    >ol::after {
      position: absolute;
      display: block;
      top: 59/@maxw;
      content: '';
      width: 1588/@maxw;
      height: 1/@maxw;
      background-color: #fff;
    }
  }
}

#navlist_bgc {
  background: linear-gradient(180deg, #074096 0%, rgba(56, 125, 226, 0) 100%);
}

#box_header {
  background: linear-gradient(180deg, #073579 0%, rgba(56, 125, 226, 0.22) 100%);
}

.submenu_mouseover {
  position: absolute;
  margin-top: 5/@maxw;
  transform: scale(1);
  width: 300/@maxw;
  min-height: 200/@maxw;
  background: #0e3c7ce2;
  transition: all .3s;
}

.submenu_mouseleave {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: all .3s;
}

.search_ {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 200/@maxw;
  right: 0;
  width: 50/@maxw;
  height: 100/@maxw;
  z-index: 999999;
  cursor: pointer;

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50/@maxw;
    background-color: #174994de;

    img {
      width: 27/@maxw;
      height: 27/@maxw;
      transition: ease .5s;
    }

    img:hover {
      transform: rotate(90deg);
    }
  }

  >span:nth-child(2) {
    position: relative;
    background-color: #0D2F62;
  }

  >span:nth-child(1):hover {
    i {
      transform: scale(1);
    }
  }

  >span:nth-child(2):hover {
    .search_nav {
      transform: scale(1);
    }
  }

  i {
    position: absolute;
    top: 0;
    left: -408/@maxw;
    width: 408/@maxw;
    height: 50/@maxw;
    background-color: #65a3ffda;
    transform: scale(0);
    transition: ease .3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    >input {
      width: 100% !important;
      height: 100% !important;
      padding: 0 0 0 10/@maxw;
      margin: 0;
      outline: none;
      border: none;
      outline: medium;
      font-size: 22/@maxw;
      color: #9F9F9F;
    }

    >button {
      cursor: pointer;
      outline: none;
      width: 20%;
      height: 100%;
      border: none !important;
      background-color: #eee;
      color: #074096;
      font-size: 20/@maxw;
    }

    >button:hover {
      background-color: #ccc;
    }
  }
}

.search_nav {
  box-sizing: border-box;
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
  width: 458/@maxw;
  height: 696/@maxw;
  background: linear-gradient(to bottom, #103A7B, #1A4D9B, #195AB9);
  border-left: 4/@maxw solid #B19A86;
  opacity: 0.9;
  transform: scale(0);
  transition: ease .3s;

  .top {
    width: 100%;
    height: 100/@maxw;
    border-bottom: 1px solid #d1baa693;
    font-size: 24/@maxw;
    color: #D1BAA6;
    display: flex;
    align-items: flex-end;

    >em {
      font-style: normal;
      background: none;
      margin: 0 0 22/@maxw 68/@maxw;
      transition: ease .3s;
    }

    >em:hover {
      color: #fff;
      font-weight: bold;
    }
  }

  .nav {
    position: relative;
    width: 150/@maxw;
    height: 85.5%;
    margin-left: 50/@maxw;
    border-left: 1/@maxw solid #d1baa693;
    border-right: 1/@maxw solid #d1baa693;
    display: flex;
    flex-direction: column;
  }

  .nav_list_box {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .spans {
    font-size: 20/@maxw;
    color: #D1BAA6;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    height: auto;
    margin-bottom: 21/@maxw;
    padding-left: 20/@maxw;
    background: none !important;
    transition: ease .3s;
    font-family: Source Han Sans CN-Medium;
  }

  .spans:first-child {
    margin-top: 25/@maxw;
  }

  .spans:hover {
    color: #fff;
    font-weight: bold;
  }

  .nav_list_boxTwo {
    width: 150%;
    position: absolute;
    top: 0;
    left: 152/@maxw;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

//如果鼠标移入最后一个导航栏，就往左显示
.submenu_mouseover_end {
  position: absolute;
  margin-left: -168/@maxw;
  margin-top: 5/@maxw;
  transform: scale(1);
  width: 300/@maxw;
  min-height: 200/@maxw;
  background: #0e3c7ce2;
  transition: all .3s;
}</style>
